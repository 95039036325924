<template>
  <div>
    <div class="mainContent">
      <a-form-model :layout="layout" style="width:100%;" ref="ruleForm" :model="form">

        <a-col :sm="24" :md="12" :lg="8" :xl="3">
          <a-form-model-item label="日期1" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="es_hosts">
            <a-date-picker placeholder="选择日期" v-model="form.date" @change="startTimeDate" format="YYYY-MM-DD" />
          </a-form-model-item>
        </a-col>
        <a-col :sm="24" :md="12" :lg="8" :xl="3">
          <a-form-model-item label="日期2" colon :labelCol='labelcol' :wrapperCol="wrappercol" prop="es_hosts">
            <a-date-picker placeholder="选择日期" v-model="form.dateTwo" @change="endTimeDate" format="YYYY-MM-DD" />
          </a-form-model-item>
        </a-col>
        <a-col :sm="24" :md="12" :lg="8" :xl="6" class="lyq_flex_grow">
          <a-form-model-item style="text-align:right;">
            <a-button type="primary" @click="search">
              搜索
            </a-button>
            <a-button @click="reset('ruleForm')">重置</a-button>
          </a-form-model-item>
        </a-col>
      </a-form-model>
    </div>

    <div class="mainContent" >

      <div class="tableHeader">
        <div class="title">数据统计</div>
        <div class="operate">
        </div>
      </div>
          <el-row style="background: #fff; padding: 16px 16px 0; margin-bottom: 32px">
            <div id="main" style="width: 100%; height: 400px"></div>
          </el-row>




    </div>



  </div>
</template>
<script>
import  {chart} from "@/axios/chart.js";
import * as echarts from "echarts";
import moment from "moment";

export default {
  name: "",
  data() {
    return {
      form: {
        date:'',
        dateTwo:'',
      },
      charts: "",
      total:'',
      arrName: [],
      today:{
        arrIP: [],
        arrPV: [],
        arrOrder: [],
      },
      yesterday:{
        arrIP: [],
        arrPV: [],
        arrOrder: [],
      },
    };
  },
  created() {
    this.form.date = moment().format('YYYY-MM-DD');
    this.form.dateTwo = moment().subtract(1, "days").format("YYYY-MM-DD");
  },
  mounted() {


    let that=this

    that.fetch();

  },
  methods: {
    moment,
    startTimeDate(date,dateString) {//时间
      this.form.date = dateString;
    },
    endTimeDate(date,dateString) {//时间
      this.form.dateTwo = dateString;
    },
    search(){//搜索
      this.fetch();
    },
    reset(formName){
      this.form.date = moment().format('YYYY-MM-DD');
      this.fetch();
    },
    //健康看板统计接口定义
    async fetch (params = {}) {
      // const params = {
      //   startTime: "2021-01-08",
      //   stopTime: "2021-02-01",
      // };
      this.dataLoading = true;
      let data=await chart({...params,...this.form})
      this.total = data.data;
      this.arrName = this.total.map((a) => a.time);
      this.today.arrIP = this.total.map((a) => a.one.ip_count);
      this.today.arrPV = this.total.map((a) => a.one.pv_count);
      this.today.arrOrder = this.total.map((a) => a.one.order_count);

      this.yesterday.arrIP = this.total.map((a) => a.two.ip_count);
      this.yesterday.arrPV = this.total.map((a) => a.two.pv_count);
      this.yesterday.arrOrder = this.total.map((a) => a.two.order_count);
      console.log(this.today,'1a')
      console.log(this.yesterday)
      this.drawLine("main");
      this.dataLoading = false;

    },
    drawLine(id) {
      this.charts = echarts.init(document.getElementById(id));

      this.charts.setOption({
        tooltip: {
          triggetotalr: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
        },
        grid: {
          left: '2%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            axisLabel: { interval: 0 ,rotate:-50},
            data: this.arrName,

          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '日期1PV',
            type: 'bar',
            stack: 'today',
            emphasis: {
              focus: 'series'
            },
            color:"rgb(84 112 198)",
            data:this.today.arrIP,
          },
          {
            name: '日期1IP',
            type: 'bar',
            stack: 'today',
            emphasis: {
              focus: 'series'
            },
            color:"rgb(145 204 117)",
            data: this.today.arrPV,
          },
          {
            name: '日期1订单',
            type: 'bar',
            stack: 'today',
            emphasis: {
              focus: 'series'
            },
            color:"rgb(238 102 102)",
            data: this.today.arrOrder,
          },
          {
            name: '日期2PV',
            type: 'bar',
            stack: 'yesterday',
            emphasis: {
              focus: 'series'
            },
            color:"rgb(115 192 222)",
            data:this.yesterday.arrIP,
          },
          {
            name: '日期2IP',
            type: 'bar',
            stack: 'yesterday',
            emphasis: {
              focus: 'series'
            },
            color:"rgb(59 162 114)",
            data: this.yesterday.arrPV,
          },
          {
            name: '日期2订单',
            type: 'bar',
            stack: 'yesterday',
            emphasis: {
              focus: 'series'
            },
            color:"rgb(252 132 82)",
            data: this.yesterday.arrOrder,
          },
        ],
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/static/css/index.scss";
.ant-table td { white-space: nowrap; }
.ant-table td .greenBtn{background-color:#7ac141;border-color:#7ac141;}

</style>
